<template>
  <div class="form--registration">
    <v-card-text>
      <v-form ref="form" :hidden="hideCheckCreditnailsForm">
        <v-row>
          <v-col class="mx-auto max-width-600">
            <v-alert
              v-show="checkcreditnailsError"
              transition="scale-transition"
              border="left"
              outlined
              type="error"
            >
              {{ checkcreditnailsErrorMessage }}
            </v-alert>
            <v-text-field
              v-model="signinForm.data.login"
              :rules="$globals.loginRules"
              class="max-width-600"
              label="Введите e-mail или телефон"
              name="login"
              type="text"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-auto max-width-600">
            <v-text-field
              v-model="signinForm.data.password"
              :rules="$globals.passwordRequiredRule"
              class="max-width-600"
              label="Введите пароль"
              name="password"
              :append-icon="show_pass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show_pass ? 'text' : 'password'"
              @click:append="show_pass = !show_pass"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col
          class="mx-auto max-width-600"
          v-if="hideCheckCreditnailsForm == false"
        >
          <div align="center">
            <v-btn
              @click="checkcreditnails"
              class="mr-2"
              color="success"
              outlined
            >
              Войти в личный кабинет
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-text>
      <v-form ref="form" :hidden="hideSigninForm">
        <v-row>
          <v-col class="mx-auto max-width-600">
            <v-alert
              v-show="signinError"
              transition="scale-transition"
              border="left"
              outlined
              type="error"
            >
              {{ signinErrorMessage }}
            </v-alert>
          </v-col>
          <v-col class="mx-auto max-width-600" :hidden="hideCheckCreditnailsForm">
            <v-text-field
              v-model="signinForm.data.login"
              :rules="$globals.loginRules"
              class="max-width-600"
              label="Введите e-mail или телефон"
              name="login"
              type="text"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-auto max-width-600" :hidden="hideCheckCreditnailsForm">
            <v-text-field
              v-model="signinForm.data.password"
              :rules="$globals.passwordRequiredRule"
              class="max-width-600"
              label="Введите пароль"
              name="password"
              prepend-icon=""
              type="password"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-auto max-width-600">
            <v-text-field
              v-model="signinForm.data.auth_code"
              class="max-width-600"
              label="Введите код подтверждения"
              name="auth_code"
              prepend-icon=""
              type="text"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col class="mx-auto max-width-600" v-if="hideSigninForm == false">
          <div align="center">
            <v-btn @click="signin" 
              class="mr-2" 
              color="success" 
              outlined
            >
              Отправить
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import qs from 'qs';

export default {
  data: () => ({
    show_pass: false,
    hideCheckCreditnailsForm: false,
    hideSigninForm: true,
    checkcreditnailsError: false,
    checkcreditnailsErrorMessage: 'Произошла ошибка авторизации, повторите попытку',
    signinError: false,
    signinErrorMessage: 'Произошла ошибка авторизации, повторите попытку',

    signinForm: {
      data: {
        login: '',
        password: '',
        auth_code: '',
      },
    },
  }),
  methods: {
    checkcreditnails() {
      this.checkcreditnailsError = false;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$http({
        method: 'post',
        url: '/udata/users/check_creditnails/.json',
        data: qs.stringify(this.signinForm),
        withCredentials: true,
      })
        .then((response) => {
          let data = response.data;
          if (data.result == true) {
            this.hideCheckCreditnailsForm = true;
            this.hideSigninForm = false;
          } else {
            if (!data.error) {
              this.checkcreditnailsError = true;
              this.checkcreditnailsErrorMessage = 'Ошибка авторизации';
            }
            if (data.error == 'no_login_error') {
              this.checkcreditnailsError = true;
              this.checkcreditnailsErrorMessage = 'Пожалуйста, введите Ваш логин';
            }
            if (data.error == 'login_error') {
              this.checkcreditnailsError = true;
              this.checkcreditnailsErrorMessage = 'Неверный логин или пароль';
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.checkcreditnailsError = true;
          this.checkcreditnailsErrorMessage =
            'Произошла ошибка  авторизации, повторите попытку';
        });
    },
    signin() {
      this.signinError = false;
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$http({
        method: 'post',
        url: '/udata/users/signin/.json',
        data: qs.stringify(this.signinForm),
        withCredentials: true,
      })
        .then((response) => {
          let data = response.data;
          if (data.result == true) {
            window.location.href = 'https://lk.k7.su/profile';
          } else {
            if (data.error == 'no_login_error') {
              this.signinError = true;
              this.signinErrorMessage = 'Пожалуйста, введите Ваш логин';
            }
            if (data.error == 'login_error') {
              this.signinError = true;
              this.signinErrorMessage = 'Неверный логин или пароль';
            }
            if (data.error == 'auth_code_expired') {
              this.signinError = true;
              this.signinErrorMessage = 'Введённый код недействителен';
            }
            if (data.error == 'auth_code_error') {
              this.signinError = true;
              this.signinErrorMessage = 'Ведён неверный код';
            }
          }
        })
        .catch((err) => {
          console.error(err);
          this.signinError = true;
          this.signinErrorMessage =
            'Произошла ошибка  авторизации, повторите попытку';
        });
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/styles.sass'

.max-width-600
  max-width: 600px

.cursor-pointer
  cursor: pointer
</style>
