<template>
  <div
    class="view--registration d-flex align-center justify-center"
    style="height: 85vh;"
  >
    <v-card class="col col-8">
      <v-toolbar class="justify-space-between" color="primary" dark flat>
        <v-toolbar-title>Вход в личный кабинет</v-toolbar-title>
      </v-toolbar>
      <sign-in-form />
    </v-card>
  </div>
</template>

<script>
import SignInForm from '@/components/forms/SignInForm.vue';
export default {
  data: () => ({}),
  components: { SignInForm },
  mounted() {
    this.$store.dispatch('startIdleTracker');
  },
};
</script>
